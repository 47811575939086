import {mapGetters, mapMutations, mapActions} from "vuex";


//sections
import comment from '@/modules/product-card/pages/comments/comment/index.vue'

export default {
    name: "question",
    components: {
        comment
    },
    data() {
        return {}
    },
    created() {

    },
    mounted() {
        document.title = `${this.currentProduct.title + ' - ' + this.$t('productCard.questions.title')} `
    },
    computed: {
        ...mapGetters({
            currentProduct: 'catalog/currentProduct',
            isAuthenticated: 'auth/isAuthenticated'
        })
    },

    methods: {
        ...mapMutations({
            closeQuestionPopup: 'card/SHOW_QUESTION_POPUP',
            clearQuestionId: 'card/SET_QUESTION_ID',
            closeRegisterPopup: 'auth/SHOW_REGISTER_POPUP',
        }),
        submit() {
            if (!this.isAuthenticated) {
                this.closeRegisterPopup(true)
            } else {
                this.clearQuestionId('')
                this.closeQuestionPopup(true)
            }
        },
        ...mapActions({})
    }
}
